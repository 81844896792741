<template>
    <div class="row center col-md-12 justify-content-center mb-1" style="padding-right:unset; padding-left:unset">
        <!-- <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/popcorn3200x800.webp" class="col-md-12" style="aspect-ratio:16/4; padding-left:0; padding-right:0" />-->
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/virutalfundraising.webp" class="col-md-12 img-fluid" style="padding-left:0; padding-right:0" />
    </div>

    <!--<div class="row center col-md-10 justify-content-center mb-5">
        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/kid_virtual.webp" class="img-fluid col-md-4" style="padding-left:0; padding-right:0" />
        <div class="col-md-6" style="color: white; background-color: #983222">
            <H1 class="mb-2" style="color: white;">WHY ONLINE FUNDRAISING?</H1>
            <p class="mt-2 ml-5 el-icon-check" style="color: white;"> The product will be shipped directly to the donator, so there is no product to distribute and no payments to collect.</p>
            <p class="mt-2 ml-5 el-icon-check" style="color: white;"> The donator will pay for their order on the Online store.</p>
            <p class="mt-2 ml-5 el-icon-check" style="color: white;"> Invites are sent directly from the computer or mobile device to donators.</p>
            <p class="mt-2 ml-5 el-icon-check" style="color: white;"> Keep 50% of what you sell. Minus the shipping and handling fee's</p>
            <p class="mt-2 ml-5 el-icon-check" style="color: white;"> No order forms or paper to keep track of.</p>

            <h2 class="mt-2 ml-5" style="color: white;">Are you ready to start a new Online Fundraiser?</h2>
            <div class="mb-2" style="text-align:center">
                <el-button type="danger" style="background-color: white; color: #983222; width: 60%; padding-bottom: 20px" size="large" @click="toNewEvent">Start Today!</el-button>
            </div>
        </div>
    </div>-->
    <!-- <hr class="col-md-12 mb-1" style="color: #983222; height: 20px; background-color: #983222;" />-->

    <div class="row center col-md-10 justify-content-center mb-5">
        <div class="col-md-10" style="color: black; justify-content:center">
            <H1 class="mb-2" style="color: black;">How does it work?</H1>
            <div class="row col-10 center">
                <p class="mt-2 " style="color: black;">1. A fundraising coordinator will choose to create a new Online Fundraiser.</p>
            </div>
            <div class="row col-10 center">
                <p class="mt-2 " style="color: black;">2. Once the fundraiser has been created, the coordinator will invite each team member/seller to create their own eCommerce store, in conjunction with the fundraiser, via email or sms.</p>
            </div>
            <div class="row col-10 center">
                <p class="mt-2 " style="color: black;">3. After the team members/sellers accept the invite, they will be brought to a seller signup page where they will be prompted to create their own account and password. </p>
            </div>
            <div class="row col-10 center">
                <p class="mt-2 " style="color: black;">4. The system will then automatically create a new online store customized for each fundraising seller. The sellers can then share a link to their store with others via SMS/Email or social media.</p>
            </div>
            <div class="row col-10 center">
                <p class="mt-2 " style="color: black;">5. Sellers will also be able to monitor store goals and store donations in their seller dashboard.</p>
            </div>

            <div class="row mb-2 col-10 center" style="text-align:center; justify-content:center">
                <el-button type="danger" style="background-color: #983222; color: white; padding-bottom: 20px; min-width:300px" size="large" @click="toNewEvent">Start Today!</el-button>
            </div>
        </div>      
    </div>
    <hr />
    <div class="row center justify-content-center" v-if="stores.length > 0">
        <h1 class="mb-2" style="color: black;">Check out some of the current online fundraising stores!</h1>
    </div>
    <div class="row center col-12 justify-content-center" v-if="stores.length > 0">
        <el-carousel interval="4000"  indicator-position="outside" height="900px" style="width:1200px">
            <el-carousel-item v-for="store in stores" :key="store.id">
                <el-card style="padding-top:30px; padding-right:60px; padding-left:60px; padding-bottom:30px;">
                    <div class="image-cover">
                        <el-image :src="store.imageSrc" style="border-radius:30px">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                        <div class="img-overlay">
                            <el-image :src="store.eventImageSrc">
                                <template #error>
                                    <div class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </template>
                            </el-image>
                        </div>
                    </div>
                    <h6 class="mt-1">Total store sales: ${{store.subTotal}} towards goal of: ${{store.targetAmount}}</h6>
                    <h6 class="mt-1">Fundraising team total sales: ${{store.eventSubTotal}}</h6>
                    <h2 class="mt-2" style="color: #983222;"><a :href="store.publicUrl" target="_blank" class="center"><h5 class="center" style="justify-content: center; color: #9A3324">{{store.title}}</h5></a></h2>
                    <h6 class="mt-2 overflow-auto" style="text-align:left">{{store.description}}</h6>
                </el-card>
            </el-carousel-item>
        </el-carousel>
    </div>

</template>

<script>
    import axios from "axios"
    import router from "../router";
    import { mapGetters } from "vuex"

export default {
        name: 'VirtualFundraising',
        data() {
            return {
                stores: [],
            }
        },
        methods: {
            toNewEvent() {
                router.push("/Signup");
            },
            toLogin() {
                router.push("/login");
            },
            openDetail(store) {
                //this.loading = true;
                router.push('/store/public/' + store.code);                
                //this.loading = false;
            },
        },
        computed: {
            ...mapGetters([
                'isAuthenticated', 'cart', 'currentUserDetail'
            ])
        },
        created() {
            axios.get("/api/stores/getpublicstores").then(response => {
                this.stores = response.data.filter(i => i.status === "Open");  
                //this.stores = response.data;  
            });
            
        }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    

    /* page content */
    h1, h2, h3, h4, h5 {
        /*font-family: Trebuchet MS;*/
        font-weight: bold;
        text-align: center;
    }
    h6 {
        /*font-family: Trebuchet MS;*/
        text-align: center;
    }

    .res-card {
        background-color: white;
    }
   
    .multi-bg-example {
        width: 100%;
        height: 800px;
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/publicwebsiteimages/5 Flavors with corn cobs.png");
        background-repeat: no-repeat;
        background-position: bottom right, left, right;
    }

    .jumbotron-welcome {
        background-image: url("https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/Kid.jpg");
        background-size: cover;
        color: white;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 380px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.0rem;
        }
        h5 {
            font-size: 1.0rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 1.5rem;
        }
        h2 {
            font-size: 1.25rem;
        }
        h5 {
            font-size: 1.00rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        p {
            font-size: 1.0rem;
        }
        h1 {
            font-size: 2.0rem;
        }
        h2 {
            font-size: 1.75rem;
        }
        h5 {
            font-size: 1.25rem;
        }
        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        p {
            font-size: 1.25rem;
        }
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 2.0rem;
        }
        h5 {
            font-size: 1.5rem;
        }
        h6 {
            font-size: 1.1rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        p {
            font-size: 1.50rem;
        }
        h1 {
            font-size: 3.0rem;
        }
        h2 {
            font-size: 2.5rem;
        }
        h5 {
            font-size: 2.0rem;
        }
        h6 {
            font-size: 1.2rem;
        }
        /*1rem = 16px*/
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

   /* .el-carousel__item h1 {
        display: flex;
        color: #475669;
        opacity: 0.75;
        line-height: 600px;
        margin: 0;
        max-width: 100%;
    }*/
</style>
