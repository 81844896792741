<template>  
    <div class="container">
        <div class="row col-12 center pt-5" style="justify-content:center">
            <iframe src="https://coloradojackgivebacksa.blob.core.windows.net/termsandconditions/ColoradoJackGiveBack.TERMS.pdf#zoom=75" id="child-iframe"></iframe>
        </div>        
    </div>
  </template>

<script>    
    import * as $ from "jquery"
    import router from "../router";

    export default {
        name: 'TermsOfUse',
        props: {
            msg: String
        },    
        methods: {
            back() {
                router.push("/Login");
            }
        },
        mounted() {
            var wi = $(window).width();
            var hi = $(window).height();

            var iframe = document.querySelector("#child-iframe");
            if (wi <= 576) {
                iframe.width = "100%";
                iframe.height = hi - 200;
            }
            else {
                iframe.width = "80%";
                iframe.height = hi - 250;
            }
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>   

    /* page content */
    h1, h2 {
        color: white;
        /*font-family: Trebuchet MS;*/
        font-weight: bold;
        text-align: center;
    }

    h1 {
        font-size: 6rem;
        margin-top: 30vh;
    }

    h2 {
        font-size: 3rem;
    }

</style>
