import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './assets/css/app.css'
import './assets/css/element-override.css'
import './assets/fontawesome-free-5.15.3-web/css/all.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosSetup from "./helpers/axios_interceptors";
import 'element-plus/lib/theme-chalk/index.css';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import * as $ from "jquery";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


Chart.register(ChartDataLabels);

import {
    ElAlert,
    ElAside,
    ElAutocomplete,
    ElAvatar,
    ElBacktop,
    ElBadge,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElButton,
    ElButtonGroup,
    ElCalendar,
    ElCard,
    ElCarousel,
    ElCarouselItem,
    ElCascader,
    ElCascaderPanel,
    ElCheckbox,
    ElCheckboxButton,
    ElCheckboxGroup,
    ElCol,
    ElCollapse,
    ElCollapseItem,
    ElCollapseTransition,
    ElColorPicker,
    ElContainer,
    ElDatePicker,
    ElDialog,
    ElDivider,
    ElDrawer,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElFooter,
    ElForm,
    ElFormItem,
    ElHeader,
    ElIcon,
    ElImage,
    ElInput,
    ElInputNumber,
    ElLink,
    ElMain,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElOption,
    ElOptionGroup,
    ElPageHeader,
    ElPagination,
    ElPopconfirm,
    ElPopover,
    ElPopper,
    ElProgress,
    ElRadio,
    ElRadioButton,
    ElRadioGroup,
    ElRate,
    ElRow,
    ElScrollbar,
    ElSelect,
    ElSlider,
    ElStep,
    ElSteps,
    ElSubmenu,
    ElSwitch,
    ElTabPane,
    ElTable,
    ElTableColumn,
    ElTabs,
    ElTag,
    ElTimePicker,
    ElTimeSelect,
    ElTimeline,
    ElTimelineItem,
    ElTooltip,
    ElTransfer,
    ElTree,
    ElUpload,
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
} from 'element-plus';

const components = [
    ElAlert,
    ElAside,
    ElAutocomplete,
    ElAvatar,
    ElBacktop,
    ElBadge,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElButton,
    ElButtonGroup,
    ElCalendar,
    ElCard,
    ElCarousel,
    ElCarouselItem,
    ElCascader,
    ElCascaderPanel,
    ElCheckbox,
    ElCheckboxButton,
    ElCheckboxGroup,
    ElCol,
    ElCollapse,
    ElCollapseItem,
    ElCollapseTransition,
    ElColorPicker,
    ElContainer,
    ElDatePicker,
    ElDialog,
    ElDivider,
    ElDrawer,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElFooter,
    ElForm,
    ElFormItem,
    ElHeader,
    ElIcon,
    ElImage,
    ElInput,
    ElInputNumber,
    ElLink,
    ElMain,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElOption,
    ElOptionGroup,
    ElPageHeader,
    ElPagination,
    ElPopconfirm,
    ElPopover,
    ElPopper,
    ElProgress,
    ElRadio,
    ElRadioButton,
    ElRadioGroup,
    ElRate,
    ElRow,
    ElScrollbar,
    ElSelect,
    ElSlider,
    ElStep,
    ElSteps,
    ElSubmenu,
    ElSwitch,
    ElTabPane,
    ElTable,
    ElTableColumn,
    ElTabs,
    ElTag,
    ElTimePicker,
    ElTimeSelect,
    ElTimeline,
    ElTimelineItem,
    ElTooltip,
    ElTransfer,
    ElTree,
    ElUpload,
]

const plugins = [
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
]

const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=b3ee4aac-8975-4892-bc79-ea11f1848ccc;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/'
        /* ...Other Configuration Options... */
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// call the axios setup method here
axiosSetup();

const createdApp = createApp(App);
createdApp.use(store).use(router).use(ElSelect, { size: 'large', zIndex: 3000 });

components.forEach(component => {
    createdApp.component(component.name, component)
})

createdApp.component('VueCropper',VueCropper);

plugins.forEach(plugin => {
    createdApp.use(plugin)
})

createdApp.directive('fancylabel', {
     //When the bound element is mounted into the DOM...
    mounted(el, binding, vnode) {
        const element = el.querySelectorAll('input,textarea,select')[0];
        if (element) {
            const $this = $(element);
            
            const $label = $this.parents(".el-form-item").find("label.el-form-item__label");
            if ($this.attr('disabled'))
                 $label.addClass("top");
            //Wait 100 ms for the input to get it's value rendered because the directive added to el-input not input
            window.setTimeout(function () {
                if (element.value != null && element.value.trim().length > 0) {
                    $label.addClass("top");
                }
            }, 100);

            $this.focus(function () {
                $label.addClass("top");
            });

        }
    }
});

createdApp.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted(el, binding, vnode) {
        const element = el.querySelectorAll('input,textarea,select')[0];
        if (element) {           
            const $this = $(element);
            const $label = $this.parents(".el-form-item").find("label.el-form-item__label");
            $label.addClass("top");
            element.focus();
        }
    }
});

createdApp.directive('focusbutton', {
    // When the bound element is mounted into the DOM...
    mounted(el, binding, vnode) {
        const element = el.getElementsByTagName('button')[0];
        if (element) {
            element.focus();
        }
    }
});


createdApp.mount('#app')

