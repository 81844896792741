<template>
    <div class="container" :loading="loading">
        <div class="row no-gutters" v-if="!editMode">
            <div class="col-8 h4 font-weight-normal pt-2 pb-2 p-sm-0 pb-sm-3 elipssis center" style="justify-content: center; text-align: center">
                <a :href="store.publicUrl" class="center"><h5 class="center" style="justify-content: center; color: #9A3324">{{storeEdit.title}}</h5></a>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col image-cover">
                <el-image style="border-radius:30px" :src="store.eventImageSrc" v-if="!editMode">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <div class="img-overlay mt-lg-5">
                    <el-image :src="store.imageSrc">
                        <template #error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </div>
                <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" v-if="editMode" :image="store.imageSrc"></ImageUploader>
            </div>
        </div>
        <div class="row pt-1 pb-1" style="justify-content:center; text-align:center">
            <div class="header-text col-10 col-lg-6 mb-1 mb-lg-0 mt-3">
                <div v-if="store.status=='Open'||store.status=='Draft'">
                    <div class="row">
                        <div class="col-12"><h4>Store will open in:</h4></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-12"><h5 style="color: #983222"><EventTimer v-bind:startDate="Date.now()" v-bind:endDate="store.startDate" v-if="store.endDate!=null"></EventTimer></h5></div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-12"><h4>Store Closed</h4></div>
                    </div>
                </div>
            </div>           
        </div>
        <div class="row no-gutters mt-lg-5" v-if="editMode">
            <div class="col-12">
                <el-form :model="storeEdit" :rules="storeEditRules" ref="storeEditForm" :label-position="top" hide-required-asterisk="true">
                    <el-form-item label="TITLE" prop="title">
                        <el-input v-model="storeEdit.title" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                    <el-form-item label="DESCRIPTION" prop="description">
                        <el-input v-model="storeEdit.description" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                    <el-form-item label="STORE TARGET AMOUNT" prop="targetamount">
                        <el-input v-model="storeEdit.targetamount" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- <hr class="d-none d-sm-block" />-->
        <div class="row">
            <div class="col-12">
                <h6><label class="top-label">Open Store Information</label></h6>
                <div class="title">
                    <div style="overflow: hidden; ">
                        <div class="row" v-if="store.status=='Open'||store.status=='Draft'">
                            <div class="col-4 font-weight-normal"><h6>Edit Store:</h6></div>
                            <el-button-group class="col-8">
                                <el-button style="width: 130px; background-color: #983222" class="mr-2" type="info" size="small" icon="el-icon-edit-outline" @click="editMode=true" v-if="!editMode" title="Edit Store, this button allows you to make changes to Store title, Description and Store Picture!">Edit Store</el-button>
                                <el-button style="width: 130px; background-color: red" class="mr-2" type="info" size="small" icon="el-icon-check" :loading="loading" @click="saveChanges" v-if="editMode" title="Save Changes to Store!">Save Changes</el-button>
                            </el-button-group>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Store Status:</h6></div>
                            <div class="col-8 text-left"><h6>{{store.status}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Store Title:</h6></div>
                            <div class="col-8 text-left"><h6>{{storeEdit.title}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Store Description:</h6></div>
                            <div class="col-8 text-left"><h6>{{storeEdit.description}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Open your Store page:</h6></div>
                            <div class="col-8 text-left">
                                <div class="col-8 elipssis pl-0" v-if="store.code!=null"><a :href="store.publicUrl" target="_blank" class="center"><h6 class="center" style="justify-content: center; color: #9A3324">{{storeEdit.title}}</h6></a></div>
                                <!--<a :href="store.publicUrl" id="storeCode" :data-copy="store.publicUrl" title="Go to Online store page"><h6 style="color: #9A3324">{{storeEdit.title}}</h6></a>-->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Copy Link to Online Store:</h6></div>
                            <el-popover placement="top"
                                        :width="650"
                                        auto-close="4000"
                                        :trigger="click"
                                        effect="dark"
                                        content="Copied Online Store to clipboard, you can now paste the link into an Email or SMS message!">
                                <template #reference>
                                    <h6 class="col-7 el-icon-copy-document c-pointer h6" style="color: #9A3324" title="Copy Online Store URL to your clipboard, then you can paste into an email or text message" id="storeCode" :data-copy="store.publicUrl" @click="copyCode"> {{store.publicUrl}}</h6>
                                </template>
                            </el-popover>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Fundraiser Start Date:</h6></div>
                            <div class="col-8 text-left"><h6>{{new Date(store.eventStartDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Fundraiser End Date:</h6></div>
                            <div class="col-8 text-left"><h6>{{new Date(store.eventEndDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row" v-if="hasRunningEvent">
                            <div class="col-4 font-weight-normal"><h6>Fundraiser:</h6></div>
                            <a class="col-8 text-left nav-link c-pointer" style="font-size:16px; color: #9A3324" @click="toEvent"><h6>Go Back to Running Fundraiser Dashboard</h6></a>
                        </div>
                        <div class="row">
                            <div class="col-4 font-weight-normal"><h6>Share Store:</h6></div>
                            <div class="col-8" v-if="store.status=='Open'||store.status=='Draft'">
                                <el-button-group>
                                    <a style="background-color:aquamarine" class="el-button el-button--small mr-2" v-bind:href="smsUrl" v-if="isMobile"><i class="fas fa-sms" style='color: white'></i></a>
                                    <el-button v-if="!isMobile" class="mr-1" size="small" type="success" icon="fas fa-sms" @click="invDonatorSMSVisible=true" title="Share your fundraising Store via SMS!"></el-button>
                                    <el-button class="mr-1" type="primary" size="small" icon="fas fa-envelope" @click="invDonatorVisible=true" title="Share your fundraising Store via Email!"></el-button>
                                    <a class="share-facebook el-button el-button--small mr-1" @click="shareOnFacebook2"><i class="fab fa-facebook" title="Share your Fundraising Store on Facebook"></i></a>
                                    <a class="share-twitter el-button el-button--small" @click="shareOnTwitter"><i class="fab fa-twitter" title="Share your Fundraising Store on Twitter"></i></a>
                                    <!--<a style="width:130px; background-color:aquamarine" class="el-button el-button--small mr-2" href="`sms:+17018665998?&body={this.store.publicUrl}`" v-if="isMobile">Share SMS on Phone</a>-->
                                </el-button-group>
                            </div>
                        </div>
                        <hr />
                        <div class="statistics">
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Store Fundrainsing Target:</h6></div>
                                <div class="col-2 text-left"><h6 class="money">{{parseFloat(storeEdit.targetamount).toFixed(2)}}</h6></div>
                            </div>
                            <!--<div class="row">
            <div class="col-4 font-weight-normal"><h6>Store Dollars Collected:</h6></div>
            <div class="col-8 text-left"><h6>${{store.total}}</h6></div>
        </div>-->
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Total Sales:</h6></div>
                                <div class="col-8 text-left"><h6 class="money">{{parseFloat(store.subTotal).toFixed(2)}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Total Earned:</h6></div>
                                <div class="col-8 text-left"><h6 class="money">{{parseFloat(store.subTotal / 2).toFixed(2)}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Number of Donations:</h6></div>
                                <div class="col-8 text-left"><h6>{{store.donationsCount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-6 font-weight-normal"><h6>Percent of Goal:</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-10 mb-3 ml-4">
                                    <el-slider v-model="storePercent" range :marks="marks"></el-slider>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12">
                <label class="top-label"><h6>STORE SALES</h6></label>
                <el-badge :value="store.donationsCount" v-if="parseInt(store.donationsCount)>0" class="count" title="This is the Number of people that have donated to your cause!">
                </el-badge>

                <div class="donators">
                    <div class="row no-gutters">
                        <div class="col-6 font-weight-normal text-left"><h6>SALE DATE</h6></div>
                        <div class="col-4 font-weight-normal text-left"><h6>BUYER NAME</h6></div>
                        <div class="col-2 font-weight-normal text-left"><h6>AMOUNT</h6></div>
                    </div>
                    <hr class="m-1" />
                    <div style="overflow: hidden;">
                        <div class="row" v-for="donator in donators" :key="donator.name">
                            <div v-if="donator.storeviewid == store.id" class="col-6 text-left">{{donator.orderdate}}</div>
                            <div v-if="donator.storeviewid == store.id" class="col-4 text-left">{{donator.name}}</div>
                            <div v-if="donator.storeviewid == store.id" class="col-2 text-left">${{donator.amount}}</div>
                        </div>
                    </div>

                    <div style="position: absolute; bottom: 10px; right: 35px;" v-if="store.status=='Open'||store.status=='Draft'">
                        <el-button size="medium" style="background-color: #983222;" type="danger" @click="invDonatorVisible=true" title="Invite new Buyers to your fundraising Store through Email, they will recieve and email invite to buy from your store!">INVITE BUYERS TO YOUR STORE</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-dialog title="INVITE BUYERS" :width="dialogWidth" :top="dialogTop" v-model="invDonatorVisible">
        <div class="" style="overflow:auto">
            <form novalidate>
                <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                          type="error"
                          effect="dark">
                </el-alert>
                <div class="row no-gutters pb-2 h5">
                    <div class="col-12 text-break">
                        Currently your store is not open for ordering, but you can still invite people to your Store via email.  Enter their email address below and they will recieve an email which will include a direct link to your Fundraiser Store.  An example email invite is show below:
                    </div>
                </div>

                <div class="row no-gutters pb-2 h5">
                    <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/invite_email.jpg" class="img-fluid center" style="max-width:100%; height:auto" />
                </div>
                <hr />
                <div class="row no-gutters pb-2 h5">
                    <div class="col-12">
                        EMAIL
                    </div>
                </div>
                <div class="row no-gutters align-items-center pb-2" v-for="donator in invitedDonators" :key="donator.counter">
                    <div class="col-9">
                        <el-input type="text" size="medium" @input="addInvitedDonator" v-model="donator.email"></el-input>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2" v-if="donator.counter > 0">
                        <el-button style="background-color: #983222" type="danger" icon="el-icon-close" circle @click="removeDonator(donator)"></el-button>
                    </div>
                </div>
            </form>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="invDonatorVisible=false">Cancel</el-button>
                <el-button style="background-color: #983222" type="danger" @click="inviteDonators" :loading="loading">INVITE</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog title="INVITE BUYERS WITH SMS" :width="dialogWidth" :top="dialogTop" v-model="invDonatorSMSVisible">
        <div class="" style="overflow:auto">
            <form novalidate>
                <el-alert v-show="errorMessage.length>0" :title="errorMessage"
                          type="error"
                          effect="dark">
                </el-alert>
                <div class="row no-gutters pb-2 h5">
                    <div class="col-12 text-break">
                        Currently your store is not open for ordering, but you can still invite buyers to view it, they can use the link later once the store is open.
                    </div>
                </div>
                <div class="row no-gutters pb-2 h5">
                    <div class="col-12">
                        PHONE NUMBER
                    </div>
                </div>
                <div class="row no-gutters align-items-center pb-2" v-for="donator in invitedDonatorsSMS" :key="donator.counter">
                    <div class="col-9">
                        <el-input type="text" size="medium" @input="addInvitedDonatorSMS" v-model="donator.phone"></el-input>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2" v-if="donator.counter > 0">
                        <el-button style="background-color: #983222" type="danger" icon="el-icon-close" circle @click="removeDonatorSMS(donator)"></el-button>
                    </div>
                </div>
            </form>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="invDonatorSMSVisible=false">Cancel</el-button>
                <el-button style="background-color: #983222" type="danger" @click="inviteDonatorsSMS" :loading="loading">INVITE</el-button>
            </span>
        </template>
    </el-dialog>


</template>

<script>

    import axios from "axios"
    import EventTimer from "./EventTimer.vue";
    import { copyToClipboard } from "../assets/js/app.js";
    import ImageUploader from "./ImageUploader"
    import router from "../router";
    import { reactive } from 'vue'
    import { mapGetters } from "vuex"
    import { initFacebookSdk, } from '@/services';

    export default {
        name: "OpenStore",
        components: { EventTimer, ImageUploader },
        data() {
            return {
                smsUrl: '',
                isMobile: false,
                errorMessage: '',
                invPartVisible: false,
                invDonatorVisible: false,
                invitedDonators: [{ counter: 0, email: '' }],
                invDonatorSMSVisible: false,
                invitedDonatorsSMS: [{ counter: 0, phone: '' }],
                store: {},
                storeEdit: {},
                loading: true,
                editMode: false,
                file: null,
                storeEditRules: {
                    title: [
                        { required: true, message: 'Title is required', trigger: 'change' }
                    ],
                    description: [
                        { required: true, message: 'Description reason is required', trigger: 'change' }
                    ],
                    targetamount: [
                        { required: true, message: 'Store Target Amount is required', trigger: 'change' }
                    ]
                }
            }
        },
        computed: {
            donators() {
                let dn = [];
                if (this.store.donators != null) {
                    this.store.donators.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1], storeviewid: items[2], orderdate: items[3] });
                    });
                }
                return dn;
            },
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "500px";
            },
            dialogTop() {
                if (screen.width <= 576)
                    return "0";
                else return "15vh";
            },
            storePercent() {
                const percentage = Math.trunc(((this.store.subTotal / 2) / this.store.targetAmount) * 100);
                return [0, percentage];
            },
            marks() {
                const marks = reactive({
                    0: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '0%',
                    },
                    20: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '20%',
                    },
                    40: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '40%',
                    },
                    60: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '60%',
                    },
                    80: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '80%',
                    },
                    100: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '100%',
                    },
                })
                return marks;
            },
            ...mapGetters([
                'hasRunningEvent', 'runningEventEndDate'
            ])
        },
        created() {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            initFacebookSdk();
            axios.get('/api/stores/' + this.$route.params.id).then(response => {
                this.loading = false;
                this.store = response.data;
                this.storeEdit.id = this.store.id;
                this.storeEdit.title = this.store.title;
                this.storeEdit.description = this.store.description;
                this.storeEdit.targetamount = this.store.targetAmount;

                this.smsUrl = "sms:?&body=" + encodeURI('Hi, please checkout my new Colorado Jack Giveback fundraising store: ' + '\n' + this.store.title + ' ' + this.store.publicUrl + '\n' + this.store.description);

                //console.log(this.smsUrl);

                //const title = this.store.title;
                //const descr = this.store.description;
                //const imgsrc = this.store.imageSrc;
                //const url = this.store.publicUrl;

                //const titleEl = document.querySelector('head meta[property="og:title"]');
                //const descEl = document.querySelector('head meta[property="og:description"]');
                //const imagEl = document.querySelector('head meta[property="og:image"]');
                //const urlEl = document.querySelector('head meta[property="og:url"]');

                //titleEl.setAttribute('content', title);
                //descEl.setAttribute('content', descr);
                //imagEl.setAttribute('content', imgsrc);
                //urlEl.setAttribute('content', url);

            }).catch((error) => {
                this.loading = false;
            });


        },
        methods: {
            addInvitedDonator() {
                this.errorMessage = '';
                let last = this.invitedDonators[this.invitedDonators.length - 1];
                if (this.validateEmail(last.email.trim())) {
                    this.invitedDonators.push({ counter: this.invitedDonators.length, email: '' });
                }
            },
            validateEmail(email) {
                var regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (email.trim().match(regExp)) {
                    return true;
                }
                else {
                    return false;
                }
            },
            removeDonator(p) {
                for (var i = 0; i < this.invitedDonators.length; i++) {
                    if (this.invitedDonators[i] === p) {
                        this.invitedDonators.splice(i, 1);
                        break;
                    }
                }
            },
            addInvitedDonatorSMS() {
                this.errorMessage = '';
                let last = this.invitedDonatorsSMS[this.invitedDonatorsSMS.length - 1];
                if (last.phone.trim().length > 9) {
                    //validate phone against regex
                    if (this.validatePhone(last.phone.trim())) {
                        this.invitedDonatorsSMS.push({ counter: this.invitedDonatorsSMS.length, phone: '' });
                    }
                    else {
                        this.errorMessage = 'invalid phone number!';
                    }
                }
            },
            validatePhone(phone) {
                var regExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
                if (regExp.test(phone)) {
                    return true;
                }
                else {
                    return false;
                }
            },
            removeDonatorSMS(p) {
                for (var i = 0; i < this.invitedDonatorsSMS.length; i++) {
                    if (this.invitedDonatorsSMS[i] === p) {
                        this.invitedDonatorsSMS.splice(i, 1);
                        break;
                    }
                }
            },
            inviteDonators() {
                this.loading = true;
                var emails = [];
                this.invitedDonators.forEach(p => {
                    if (p != '' && this.validateEmail(p.email))
                        emails.push(p.email);
                });

                axios.post('/api/participants/InviteDonator/', { storeId: this.store.id, emails: emails }).then(response => {
                    this.loading = false;
                    this.invDonatorVisible = false;
                    this.$message({
                        message: 'Donator emails have been sent and invited to view your store! - Emails: ' + emails.toString(),
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            inviteDonatorsSMS() {
                this.loading = true;
                var phones = [];
                this.invitedDonatorsSMS.forEach(p => {
                    if (p != '' && this.validatePhone(p.phone))
                        phones.push(p.phone);
                });

                axios.post('/api/participants/SendSMSDonorInvite/', { storeId: this.store.id, phones: phones }).then(response => {
                    this.loading = false;
                    this.invDonatorSMSVisible = false;
                    this.$message({
                        message: 'Donators have been sent an sms message and invited to view your store!',
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            shareOnFacebook3() {
                window.FB.ui(
                    {
                        method: 'feed',
                        name: this.store.title,
                        link: this.store.publicUrl,
                        description: this.store.description,
                        display: 'popup',
                        picture: this.store.imageSrc
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            shareOnFacebook2() {
                window.FB.ui(
                    {
                        method: 'share_open_graph',
                        action_type: 'og.shares',
                        display: 'popup',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': this.store.publicUrl,
                                'og:title': this.store.title,
                                'og:description': this.store.description,
                                'og:image:width': '600',
                                'og:image:height': '600',
                                'og:image': this.store.imageSrc
                            }
                        })
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            shareOnFacebook() {
                window.FB.ui(
                    {
                        method: 'share',
                        href: this.store.publicUrl,
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            sendOnFacebook() {
                window.FB.ui(
                    {
                        method: 'send',
                        link: this.store.publicUrl,
                    },
                    // callback
                    function (response) {
                        if (response && !response.error_message) {
                            this.$message({
                                message: 'Successfully shared your public fundraising store on facebook!',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: 'Error sharing your public fundraising store on facebook! ' + response.error_message,
                                type: 'error'
                            });
                        }
                    }
                );
            },
            //sendSMS() {
            //    this.smsUrl = "sms:?& body=" + this.store.publicUrl
            //},
            shareOnTwitter() {
                window.open("https://twitter.com/share?url=" + this.store.publicUrl, "_blank");
            },
            copyCode() {
                copyToClipboard("storeCode");
            },
            setCropData(data) {
                this.storeEdit.imageCropData = data;
            },
            setSelectedImage(file) {
                this.file = file;
            },
            saveChanges() {
                this.$refs["storeEditForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        var formData = new FormData();
                        if (this.file != null) {
                            formData.append("file", this.file.raw);
                            for (const [key, value] of Object.entries(this.storeEdit.imageCropData)) {
                                formData.append("imageCropData." + key, value);
                            }
                        }

                        for (const [key, value] of Object.entries(this.storeEdit)) {
                            if (key === "imageCropData")
                                continue;
                            formData.append(key, value);
                        }

                        axios.put('/api/stores', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.loading = false;
                            this.editMode = false;
                            this.store.imageSrc = response.data;
                            this.$message({
                                message: 'Changes saved to the store.',
                                type: 'success'
                            });
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
            toEvent() {
                router.push("/event/running");
            },
        }
    }
</script>

<style scoped>
    img {
        height: 300px;
        object-fit: cover;
        border-radius: 4px;
        width: 100%
    }

    .participants, .donators {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        min-height: 250px;
    }

    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: normal;
        padding: 0 5px;
    }

    .count {
        top: 25px;
        left: 15px;
    }

    .title {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        /*min-height: 200px;*/
        flex-direction: inherit;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 370px) {
        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .80rem;
            font-weight: normal;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: .95rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        h5 {
            font-size: 1.75rem;
        }

        h6 {
            font-size: 1.25rem;
        }
        /*1rem = 16px*/
    }
</style>